<template>
  <div class="case">
    <!-- head -->
    <div class="head">
      <Navigator />
      <h3>项目案例</h3>
      <p>Project case</p>
    </div>
    <!-- head -->
    <!-- content -->
    <div class="page">
      <template v-for="(item, i) in imgArr">
        <div
          :key="i"
          id="pageHead"
          :style="'background: url(' + item.imgBg + ') no-repeat 100%;background-size: 100% 100%;'"
          :data-value="i"
          @click="goFn(i)"
          :class="num == i ? 'activeF' : ''"
        >
          <p :class="num == i ? 'active' : ''" @click="goFn(i)">
            {{ item.text }}
          </p>
        </div>
      </template>
    </div>
    <div class="content">
      <router-view></router-view>
    </div>
    <!-- content -->
    <!-- 其他客户案例 -->
    <div class="caseArrs">
      <h3>其它客户项目案例</h3>
      <div class="caseArr">
        <template v-for="(item, i) in caseArr">
          <div :key="i" class="caseArrI">
            <img :src="item.imgSrc" alt="" class="imgSrc" />
            <h6>{{ item.title }}</h6>
            <p>{{ item.text }}</p>
          </div>
        </template>
      </div>
    </div>
    <!-- 其他客户案例 -->
    <!-- Footer -->
    <Footer />
    <!-- Footer -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      num: 0,
      imgArr: [
        {
          imgBg: require("@/assets/img/case-bj1.png"),
          imgBg1: require("@/assets/img/case-bj11.png"),
          text: "茶企数字化案例",
        },
        {
          imgBg: require("@/assets/img/case-bj2.png"),
          imgBg1: require("@/assets/img/case-bj22.png"),
          text: "区域茶产业管理服务平台",
        },
        {
          imgBg: require("@/assets/img/case-bj3.png"),
          imgBg1: require("@/assets/img/case-bj33.png"),
          text: "消费品行业案例",
        },
        {
          imgBg: require("@/assets/img/case-bj4.png"),
          imgBg1: require("@/assets/img/case-bj44.png"),
          text: "工厂智能化案例",
        },
        {
          imgBg: require("@/assets/img/case-bj5.png"),
          imgBg1: require("@/assets/img/case-bj55.png"),
          text: "医疗卫生行业案例",
        },
      ],
      caseArr: [
        {
          imgSrc: require("@/assets/img/icon7.png"),
          title: "下关沱茶",
          text: "2018年，下关沱茶在普洱茶行业率先迎来了革新的浪潮，传统内飞与RFID技术结合，由秀圆果公司提供方案。 ",
        },
        {
          imgSrc: require("@/assets/img/同兴庄园.png"),
          title: "同兴庄园",
          text: "同兴庄园是西双版纳易武茶叶协会会长单位，秀圆果公司为其定制电子内飞产品，并作为范本在协会茶企内推广。 ",
        },
        {
          imgSrc: require("@/assets/img/icon9.png"),
          title: "千瓯雪",
          text: "作为普洱茶品牌中的一匹黑马——千瓯雪，在2021年与秀圆果公司合作，使用RFID电子内飞赋能品牌推广。 ",
        },
        {
          imgSrc: require("@/assets/img/icon14.png"),
          title: "古度",
          text: "古度早前使用RFID普通外贴标签，在2022年升级采用秀圆果公司的电子内飞产品，全面保障古度品牌安全。 ",
        },
        {
          imgSrc: require("@/assets/img/icon20.png"),
          title: "畲谷幽兰",
          text: "畲谷幽兰是福鼎太姥山的知名茶企，是秀圆果与福鼎白茶首家合作茶企。开启了白茶电子内飞应用的先河。 ",
        },
        {
          imgSrc: require("@/assets/img/柏柳.png"),
          title: "柏柳茶业",
          text: "柏柳茶业位于“中国白茶第一村的”福鼎市柏柳村，电子内飞的应用，为进一步开拓白茶市场奠定了基础。 ",
        },
      ],
    };
  },

  methods: {
    numFn() {
      let n = sessionStorage.getItem("n") || 0;
      this.num = n;
    },
    goFn(key) {
      sessionStorage.setItem("n", key);
      this.num = key;
      switch (key) {
        case 0:
          this.$router.push({ path: "/teaCase" });
          break;
        case 1:
          this.$router.push({ path: "/teaCaseWeb" });
          break;
        case 2:
          this.$router.push({ path: "/healthCase" });
          break;
        case 3:
          this.$router.push({ path: "/factoryCase" });
          break;
        case 4:
          this.$router.push({ path: "/hygieneCase" });
          break;

        default:
          break;
      }
    },
  },
  created() {
    this.numFn();
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 750px) {
  .case {
    height: 100%;
    position: relative;
    // overflow: hidden;
    .page {
      display: none;
    }
    .head {
      height: 8.19rem;
      width: 100%;
      overflow: hidden;
      background: url(../../assets/img/case0.jpg) no-repeat;
      background-size: 100% 100%;
      // margin-top: 50px;
      h3 {
        font-size: 1.02rem;
        font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
        font-weight: bold;
        color: #ffffff;
        line-height: 1.18rem;
        text-shadow: 0px 1px 1px rgba(108, 108, 108, 0.25);
        margin-top: 4.5rem;
        margin-left: 7.7rem;
      }
      p {
        font-size: 0.41rem;
        font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
        font-weight: bold;
        color: #ffffff;
        text-shadow: 0px 1px 1px rgba(108, 108, 108, 0.25);
        // margin-left: 8.38rem;
        text-align: center;
      }
    }
    .caseArrs {
      width: 100%;
      text-align: center;
      padding-bottom: 2.7rem;
      h3 {
        margin: 1.36rem 0 1rem;
        font-size: 0.72rem;
        font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
        font-weight: bold;
        color: #093c7f;
      }
      .caseArr {
        width: 16.13rem;
        margin: auto;
        .caseArrI {
          width: 16.13rem;
          height: 5.38rem;
          border-radius: 0.26rem;
          box-shadow: 0px 0px 0.26rem 0px rgba(0, 0, 0, 0.302);
          margin-bottom: 0.51rem;
          position: relative;
          h6 {
            font-size: 0.92rem;
            font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
            font-weight: bold;
            color: #000000;
            line-height: 1.28rem;
            position: absolute;
            top: 0.56rem;
            left: 4.86rem;
          }
          p {
            text-align: left;
            width: 10.55rem;
            // height: 2.1rem;
            font-size: 0.61rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            overflow: hidden;
            // line-height: 0.23rem;
            position: absolute;
            top: 2.3rem;
            left: 4.86rem;
          }
          .imgSrc {
            position: absolute;
            top: 0.56rem;
            left: 0.56rem;
            width: 4.24rem;
            height: 4.24rem;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 19200px) and (min-width: 751px) {
  .case {
    height: 100%;
    position: relative;
    .page {
      height: 2.4rem;
      width: 16.5rem;
      margin: auto;
      display: flex;
      justify-content: space-around;
      overflow: hidden;
      #pageHead {
        width: 3rem;
        height: 1.8rem;
        margin-top: 0.2rem;
        font-size: 0.26rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #454545;
        text-align: center;
        cursor: pointer;
        transition: all 0.5s;
        border-radius: 0.1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          width: 2.34rem;
        }
        .active {
          color: #093c7f;
          font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
          font-weight: bold;
          transform: scale(1.1);
        }
      }
      .activeF {
        box-shadow: 0px 0px 0.1rem 0px rgba(0, 0, 0, 0.302);
      }
      #pageHead:hover {
        transform: scale(1.1);
        color: #093c7f;
        box-shadow: 0px 0px 0.1rem 0px rgba(0, 0, 0, 0.302);
      }
    }
    .head {
      height: 5.48rem;
      width: 19.2rem;
      overflow: hidden;
      background: url(../../assets/img/case0.jpg) no-repeat;
      background-size: 100% 100%;
      h3 {
        width: 4.5rem;
        height: 1.4rem;
        font-size: 1rem;
        font-family: PingFang SC-Heavy, PingFang SC;
        font-weight: 800;
        color: #fff;
        line-height: 1.17rem;
        text-shadow: 0rem 0.04rem 0.04rem rgba(108, 108, 108, 0.25);
        margin-top: 2.23rem;
        margin-left: 7.6rem;
      }
      p {
        height: 0.56rem;
        font-size: 0.4rem;
        font-family: PingFang SC-Heavy, PingFang SC;
        font-weight: 800;
        color: #ffffff;
        line-height: 0.47rem;
        text-shadow: 0rem 0.04rem 0.04rem rgba(108, 108, 108, 0.25);
        margin-left: 8.3rem;
      }
    }
    .caseArrs {
      text-align: center;
      padding-bottom: 2.7rem;
      h3 {
        margin: 1.36rem 0 1rem;
        height: 0.84rem;
        font-size: 0.4rem;
        font-family: PingFang SC-Heavy, PingFang SC;
        font-weight: 800;
        color: #093c7f;
        line-height: 0.7rem;
      }
      .caseArr {
        width: 16.7rem;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        .caseArrI {
          width: 5.4rem;
          height: 1.8rem;
          margin-right: 0.16rem;
          margin-top: 0.2rem;
          // background: url(../../../assets/img/case-bj6.png) no-repeat;
          // background-size: 100% 100%;
          box-shadow: 0px 0px 0.1rem 0px rgba(0, 0, 0, 0.302);
          border-radius: 0.1rem;
          position: relative;
          h6 {
            height: 0.56rem;
            font-size: 0.3rem;
            font-family: PingFang SC-Heavy, PingFang SC;
            font-weight: 800;
            color: #000000;
            line-height: 0.47rem;
            position: absolute;
            top: 0.19rem;
            left: 1.63rem;
            margin-left: 0.1rem;
          }
          p {
            margin-left: 0.1rem;
            text-align: left;
            width: 3.4rem;
            // height: 0.8rem;
            font-size: 0.2rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            overflow: hidden;
            // line-height: 0.23rem;
            position: absolute;
            top: 0.77rem;
            left: 1.63rem;
          }
          .imgSrc {
            position: absolute;
            top: 0.19rem;
            left: 0.21rem;
            width: 1.42rem;
            height: 1.42rem;
          }
        }
      }
    }
  }
}
</style>
